@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  font-family: 'JetBrains Mono', monospace;
}

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-16 w-16 mt-2 mb-2 mx-auto  
  bg-sideBarPrimary hover:bg-sideBarSecondary
  text-sideBarHover hover:text-sideBarHover
    hover:rounded-xl
    transition-all duration-300 ease-linear
    cursor-pointer ;
  }

  .sidebar-icon.clicked{
    @apply relative flex items-center justify-center 
    h-16 w-16 mt-2 mb-2 mx-auto  
  bg-sideBarSecondary 
  text-sideBarHover
    rounded-xl
    transition-all duration-300 ease-linear
    cursor-pointer ;
  }

  .sidebar-page-name {
    @apply absolute h-16 w-auto p-2 m-2 min-w-max left-24 rounded-r-lg
    text-sideBarHover bg-sideBarSecondary
    text-xl font-bold flex items-center
    transition-all duration-200 scale-0 origin-left  ease-linear;
  }
}